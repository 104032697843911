import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import HelmetProvider
import Home from "./pages/home";
import Champs from "./pages/champs";
import Contests from "./pages/contests";
import Register from "./pages/register";
import Header from "./components/header";
import Footer from "./components/footer";
import UserProfile from "./pages/user-profile";
import Post from "./pages/post";
import { Box } from "@mui/material";
import ScrollToTop from "./components/scroll-to-top";

export default function App() {
  const downloadAppRef = useRef<HTMLDivElement>(null);

  const scrollToAppDownload = () => {
    if (downloadAppRef.current) {
      downloadAppRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Router>
        <ScrollToTop />
        <Header scrollToAppDownload={scrollToAppDownload} />
        <Box
          component="main"
          sx={{ flexGrow: 1, my: 2, width: "100vw", px: 0 }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/champs" element={<Champs />} />
            <Route path="/contests" element={<Contests />} />
            <Route path="/register" element={<Register />} />
            <Route path="/user/:handle" element={<UserProfile />} />
            <Route path="/post/:postId" element={<Post />} />
          </Routes>
        </Box>
        <Footer ref={downloadAppRef} />
      </Router>
    </Box>
  );
}
