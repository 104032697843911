import React from "react";
import { Box } from "@mui/material";

const SectionBackground = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
        background: `linear-gradient(78.47deg, #FDB365 -7.11%, #FC3561 107.74%)`,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }}
      >
        <svg
          width="1500"
          height="207"
          viewBox="0 0 1500 207"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ display: "block" }}
        >
          <path
            d="M1559.14 123.211C1559.14 123.211 1552.33 69.0861 1275.74 100.788C999.153 132.489 777.064 308.007 431.668 123.211C86.2726 -61.5856 -81.9972 18.0545 -81.9972 18.0545L-47.9344 -217L1581.62 -174.474"
            fill="white"
          />
        </svg>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "auto",
        }}
      >
        <svg
          width="1500"
          height="124"
          viewBox="0 0 1500 124"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ display: "block" }}
        >
          <path
            d="M-59.5186 49.1198C-59.5186 49.1198 -52.7061 80.9826 223.883 62.3201C500.472 43.6576 722.561 -59.669 1067.96 49.1198C1413.35 157.909 1581.62 111.025 1581.62 111.025L1547.56 249.4L-82 224.365"
            fill="white"
          />
        </svg>
      </Box>
    </Box>
  );
};

export default SectionBackground;
