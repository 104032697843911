import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import axiosInstance from "../../api/axiosConfig";
import { PostDetail } from "../../types";
import { POST_BY_IDS_API } from "../../api/endpoint";
import PostDetailDialog from "./post-detail";
import Loader from "../../components/loader";

const theme = createTheme({
  typography: {
    fontFamily: '"Somatic", Arial, sans-serif',
  },
});

const Post: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const navigate = useNavigate();
  const [postDetail, setPostDetail] = useState<PostDetail | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPostDetails = async () => {
    try {
      const response = await axiosInstance.get(`${POST_BY_IDS_API}${postId}`);
      setPostDetail(response.data.data[0]);
      setLoading(false);
    } catch {
      setError("Failed to fetch post details.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPostDetails();
  }, [postId]);

  if (loading) return <Loader />;
  if (error) return <Box>{error}</Box>;

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <PostDetailDialog
          open={true}
          onClose={handleClose}
          postDetail={postDetail}
        />
      </Box>
    </ThemeProvider>
  );
};

export default Post;
