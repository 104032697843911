import React from "react";

interface ActiveGradientButtonProps {
  width?: number;
  height?: number;
  text: string; // Text to display over the SVG
  onClick: () => void; // onClick handler for the SVG
}

const ActiveGradientButton: React.FC<ActiveGradientButtonProps> = ({
  width = 95, // Default width
  height = 41, // Default height
  text,
  onClick,
}) => {
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 95 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick} // onClick event handler
        style={{ cursor: "pointer" }}
      >
        <path
          d="M0 31.4344V9.54109C0 5.75533 2.14236 2.50725 5.88748 1.95399C23.5885 -0.660981 72.7338 -0.738096 89.6955 2.24964C93.1533 2.85872 95 5.93159 95 9.44265V31.4485C95 34.8811 93.24 37.9182 89.8687 38.5639C72.961 41.8018 23.1423 41.7206 5.61898 38.8629C2.00324 38.2733 0 35.0979 0 31.4344Z"
          fill="url(#paint0_linear_3874_311906)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3874_311906"
            x1="0"
            y1="20.5"
            x2="95"
            y2="20.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#461289" />
            <stop offset="0.53" stopColor="#7E31E1" />
            <stop offset="1" stopColor="#9421CD" />
          </linearGradient>
        </defs>
      </svg>
      <div
        style={{
          fontFamily: "Somatic",
          fontSize: "13px",
          position: "absolute",
          top: "-3px",
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
          fontWeight: 400,
          color: "white",
          letterSpacing: 1,
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default ActiveGradientButton;
