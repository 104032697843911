import React, { useEffect, useState } from "react";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import TimelineButtons from "../champs/timeline-buttons";
import style from "./style.module.css";
import { NavLink } from "react-router-dom";
import Loader from "../../components/loader";
import { CONTEST_API } from "../../api/endpoint";
import axiosInstance from "../../api/axiosConfig";
import ContestDetailDialog from "./context-detail";
import { ContestDetail } from "../../types";

const Contests = () => {
  const [timeline, setTimeline] = useState("ongoing");
  const [data, setData] = useState<any[]>([]);
  const [selectedContest, setSelectedContest] = useState<any | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleTimelineChange = (newTimeline: string) => {
    console.log(newTimeline);
    if (newTimeline !== timeline) {
      setTimeline(newTimeline);
    }
  };

  const items = ["ongoing", "completed", "upcoming"];
  const pageToken = "";
  const count = 10;

  const fetchContest = async (pageToken: string, count: number) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${CONTEST_API}${timeline}`, {
        params: { page: pageToken, count },
      });
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching contests:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("timeline", timeline);
  useEffect(() => {
    fetchContest(pageToken, count);
  }, [timeline]);

  const openDialog = (contest: any) => {
    setSelectedContest(contest);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setSelectedContest(null);
  };

  const SingleCard = ({
    contestId,
    filename,
    title,
    desc,
    ageLimit,
    date,
    item,
  }: {
    contestId: string;
    filename: string;
    title: String;
    desc: [];
    ageLimit: String;
    date: any;
    item: ContestDetail;
  }) => {
    const description = desc.join(",");
    return (
      <Box className={style.cardView}>
        <Box className={style.bannerimg}>
          <img
            src={`${process.env.REACT_APP_DEFAULT_AVATAR}/CONTESTS/${contestId}/IMAGES/medium/${filename}`}
          />
        </Box>
        <Box className={style.bannerimg}>
          <img
            src={`${process.env.REACT_APP_DEFAULT_AVATAR}/CONTESTS/${contestId}/IMAGES/medium/${filename}`}
          />
        </Box>
        <Box className={style.overlay}>
          <Box className={style.top}></Box>
          <Box className={style.bottom}></Box>
        </Box>
        <Box className={style.blurrr}>
          <Typography
            sx={{ padding: "5px", fontSize: "20px", fontFamily: "somatic" }}
          >
            {title}
          </Typography>
          <Typography
            className={style.desc}
            sx={{
              padding: "3px",
              fontSize: "14px",
              fontFamily: "somatic",
              opacity: "0.7",
            }}
          >
            {description}
          </Typography>
          <Typography
            sx={{
              padding: "2px",
              fontSize: "14px",
              fontFamily: "somatic",
              opacity: "0.7",
            }}
          >{`Age Limit: ${ageLimit}`}</Typography>
          <Box sx={{ padding: "10px" }}>
            <NavLink
              onClick={() => openDialog(item)}
              className={"contest_btn"}
              to="#"
            >
              <Typography sx={{ fontSize: "17px", fontFamily: "somatic" }}>
                View Results
              </Typography>
            </NavLink>
          </Box>
        </Box>
        <Box className={style.overlayblur}>
          <Typography
            sx={{
              textAlign: "center",
              color: "white",
              fontSize: "12px",
              fontWeight: "200",
              fontFamily: "somatic",
            }}
          >{`Starts in Apr 20 at 11 am`}</Typography>
        </Box>
      </Box>
    );
  };

  console.log("data", data);
  return (
    <>
      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <TimelineButtons
          page={"contest"}
          items={items}
          timeline={timeline}
          handleTimelineChange={handleTimelineChange}
        />
      </Grid>
      {loading && <Loader />}
      {!loading && data.length !== 0 ? (
        <Box className={style.cardContainer}>
          {data.map((item: any, index: number) => (
            <SingleCard
              key={index}
              contestId={item.contest_id}
              filename={item.cr_banner}
              title={item.title}
              desc={item.description}
              ageLimit={item.age_limit}
              date={item.from}
              item={item}
            />
          ))}
        </Box>
      ) : (
        !loading && (
          <Box sx={{ textAlign: "center", padding: "50px" }}>
            <Typography>
              {timeline === "ongoing"
                ? "No Ongoing contest"
                : timeline === "upcoming"
                ? "No Upcoming contest"
                : "No contest completed"}
            </Typography>
          </Box>
        )
      )}
      {selectedContest && (
        <ContestDetailDialog
          open={dialogOpen}
          onClose={closeDialog}
          contestDetail={selectedContest}
        />
      )}
    </>
  );
};

export default Contests;
