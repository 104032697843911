import React from "react";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { UserCounters } from "../types";
import works from "../assets/works.png";
import collections from "../assets/collections.png";
import books from "../assets/books.png";
import videos from "../assets/videos.png";
import contests from "../assets/contests.png";
import achievements from "../assets/achievements.png";

interface UserScoresProps {
  userCounters: UserCounters | null;
  isMobile: boolean | null;
}

const UserScores: React.FC<UserScoresProps> = ({ userCounters, isMobile }) => {
  return (
    <Box mt={4}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {[
          { label: "Works", value: userCounters?.posts, icon: works },
          {
            label: "Collections",
            value: userCounters?.collections,
            icon: collections,
          },
          { label: "Books", value: userCounters?.books, icon: books },
          { label: "Videos", value: 0, icon: videos },
          { label: "Contests", value: userCounters?.contests, icon: contests },
          {
            label: "Achievements",
            value: userCounters?.achievements,
            icon: achievements,
          },
        ].map(({ label, value, icon }, idx) => (
          <Grid key={idx} size={{ xs: 4, md: 2 }} sx={{ textAlign: "center" }}>
            <img
              src={icon}
              alt={label}
              width={isMobile ? "75%" : "70%"}
              height={isMobile ? "75%" : "70%"}
            />
            <Typography fontSize={{ xs: "13px", md: "22.46px" }} fontFamily="Somatic">
              {label}
            </Typography>
            <Typography
              fontSize={{ xs: "13px", md: "22.46px" }}
              fontFamily="Somatic"
              sx={{
                background: isMobile
                  ? "linear-gradient(89.82deg, #C80864 0.16%, #FA4E76 0.17%, #0848EC 99.85%)"
                  : "rgba(31, 29, 58, 0.6)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              {value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UserScores;
