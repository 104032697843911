import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import lovely from "../assets/lovely.json";

interface LovelyProps {
  width?: string | number;
  height?: string | number;
}

const Lovely: React.FC<LovelyProps> = ({
  width = "150px",
  height = "150px",
}) => {
  return (
    <Player autoplay loop src={lovely} style={{ width, height }} />
  );
};

export default Lovely;
