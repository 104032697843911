import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import axiosInstance from "../../api/axiosConfig";
import axios from "axios";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { useSwipeable } from "react-swipeable";
import { useMediaQuery } from "@mui/material";
import { Post, PostDetail } from "../../types";
import { LANDSCAPE_API, POST_BY_IDS_API } from "../../api/endpoint";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";

const TopLandscapes = () => {
  const navigate = useNavigate();
  const [landscapes, setLandscapes] = useState<Post[]>([]);
  const [postDetails, setPostDetails] = useState<PostDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<string | null>(null);
  const isFetching = useRef(false);
  const [activeSlide, setActiveSlide] = useState(2);
  const [stopExecution, setStopExecution] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");
  const slideWidth = isMobile ? 200 : 460;
  const slideHeight = isMobile ? 150 : 292;

  const fetchLandscapes = async (
    pageToken: string | null = null,
    count = 5,
  ) => {
    if (!stopExecution && !isFetching.current) {
      isFetching.current = true;

      try {
        const response = await axiosInstance.get(LANDSCAPE_API, {
          params: { page: pageToken || "", count },
        });

        if (!response?.data?.data?.length) {
          setStopExecution(true);
          return;
        }

        setLandscapes((prevLandscapes) => [
          ...prevLandscapes,
          ...response.data.data,
        ]);
        setCurrentPage(response.data.page);

        const postIds = response.data.data.map(
          (landscape: Post) => landscape.post_id,
        );
        if (postIds.length > 0) {
          const postDetailsResponse = await axiosInstance.get(
            `${POST_BY_IDS_API}${postIds.join(",")}`,
          );
          setPostDetails((prevPostDetails) => [
            ...prevPostDetails,
            ...postDetailsResponse.data.data,
          ]);
        }
      } catch (error) {
        setError(
          axios.isAxiosError(error)
            ? error.message
            : "An unknown error occurred",
        );
      } finally {
        setLoading(false);
        isFetching.current = false;
      }
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    delta: 10,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  useEffect(() => {
    fetchLandscapes();
  }, []);

  useEffect(() => {
    if (postDetails.length < 5 && currentPage) {
      fetchLandscapes(currentPage, 5 - postDetails.length);
    }
  }, [postDetails, currentPage]);

  const validPostDetails = landscapes
    .map((landscape) =>
      postDetails.find((detail) => detail.post_id === landscape.post_id),
    )
    .filter((detail): detail is PostDetail => detail !== undefined);

  const next = () => {
    if (activeSlide < validPostDetails.length - 1) {
      setActiveSlide(activeSlide + 1);
    } else if (activeSlide === validPostDetails.length - 1 && !stopExecution) {
      fetchLandscapes(currentPage);
    }
  };

  const prev = () => {
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    }
  };

  const getStyles = (index: number) => {
    const screenWidth = window.innerWidth;
    const baseTranslateX = isMobile ? screenWidth * 0.15 : screenWidth * 0.17;
    const baseTranslateZ = isMobile ? screenWidth * 0.6 : screenWidth * 0.2;

    if (activeSlide === index) {
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10,
      };
    } else if (activeSlide - 1 === index) {
      return {
        opacity: 1,
        transform: `translateX(-${baseTranslateX}px) translateZ(-${baseTranslateZ}px) rotateY(35deg)`,
        zIndex: 9,
      };
    } else if (activeSlide + 1 === index) {
      return {
        opacity: 1,
        transform: `translateX(${baseTranslateX}px) translateZ(-${baseTranslateZ}px) rotateY(-35deg)`,
        zIndex: 9,
      };
    } else if (activeSlide - 2 === index) {
      return {
        opacity: 1,
        transform: `translateX(-${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(35deg)`,
        zIndex: 8,
      };
    } else if (activeSlide + 2 === index) {
      return {
        opacity: 1,
        transform: `translateX(${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(-35deg)`,
        zIndex: 8,
      };
    } else if (index < activeSlide - 2) {
      return {
        opacity: 0,
        transform: `translateX(-${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(35deg)`,
        zIndex: 7,
      };
    } else if (index > activeSlide + 2) {
      return {
        opacity: 0,
        transform: `translateX(${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(-35deg)`,
        zIndex: 7,
      };
    }
  };

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  const handlePostClick = (detail: PostDetail, index: number) => {
    setActiveSlide(index);
    navigate(`/post/${detail.post_id}`);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography
        variant={isMobile ? "h6" : "h4"}
        sx={{
          background:
            "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          color: "transparent",
          fontWeight: 400,
          letterSpacing: isMobile ? 1 : 3,
          mb: isMobile ? 2 : 5,
        }}
      >
        Top Landscapes
      </Typography>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ width: "100%", maxWidth: "80%" }}>
          <div
            className="slideC"
            {...handlers}
            style={{ width: slideWidth, height: slideHeight }}
          >
            {validPostDetails.map((postDetail, index) => (
              <React.Fragment key={postDetail.post_id + index}>
                <div
                  className="slide"
                  style={{
                    width: slideWidth,
                    height: slideHeight,
                    ...getStyles(index),
                  }}
                  onClick={() => handlePostClick(postDetail, index)}
                >
                  {postDetail.files.length > 0 && (
                    <img
                      src={`${process.env.REACT_APP_DEFAULT_AVATAR}/${postDetail.user_id}/WORKS/IMAGES/medium/${postDetail.files[0].name}`}
                      alt={postDetail.text}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      onError={(e) =>
                        ((e.target as HTMLImageElement).src =
                          process.env.REACT_APP_NO_IMAGE || "")
                      }
                      className="slide-img"
                    />
                  )}
                </div>
                <div
                  className="reflection"
                  style={{
                    background: `linear-gradient(to bottom, rgba(245, 245, 245, 0.4), transparent)`,
                    ...getStyles(index),
                  }}
                />
              </React.Fragment>
            ))}
          </div>

          <div className="btns">
            <IconButton
              className="slider-btn prev-btn"
              onClick={prev}
              sx={{ color: "red" }}
              aria-label="Previous slide"
            >
              <ArrowBack />
            </IconButton>
            <IconButton
              className="slider-btn next-btn"
              onClick={next}
              sx={{ color: "red" }}
              aria-label="Next slide"
            >
              <ArrowForward />
            </IconButton>
          </div>
        </Box>
      )}
    </Box>
  );
};

export default TopLandscapes;
