import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import epic from "../assets/epic.json";

interface EpicProps {
  width?: string | number;
  height?: string | number;
}

const Epic: React.FC<EpicProps> = ({ width = "150px", height = "150px" }) => {
  return <Player autoplay loop src={epic} style={{ width, height }} />;
};

export default Epic;
