import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import loader_lottie from "../assets/loader_lottie.json";

const Loader: React.FC = () => {
  return (
    <Player
      autoplay
      loop
      src={loader_lottie}
      style={{ height: "150px", width: "150px" }}
    />
  );
};

export default Loader;
