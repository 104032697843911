import React from "react";
import Box from "@mui/material/Box";
import { Typography, IconButton, useMediaQuery } from "@mui/material";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { useSwipeable } from "react-swipeable";

interface MemberSliderProps {
  members: {
    id: string;
    icon: React.ReactNode;
    title: string;
  }[];
  activeSlide: number;
  setActiveSlide: (index: number) => void;
  next: () => void;
  prev: () => void;
  slideSize: number;
}

const MemberSlider: React.FC<MemberSliderProps> = ({
  members,
  activeSlide,
  setActiveSlide,
  next,
  prev,
  slideSize,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    delta: 10,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const getStyles = (index: number) => {
    const screenWidth = window.innerWidth;
    const baseTranslateX = isMobile ? screenWidth * 0.2 : screenWidth * 0.15;
    const baseTranslateZ = isMobile ? screenWidth * 0.4 : screenWidth * 0.3;

    if (activeSlide === index) {
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10,
      };
    } else if (activeSlide - 1 === index) {
      return {
        opacity: 1,
        transform: `translateX(-${baseTranslateX}px) translateZ(-${baseTranslateZ}px) rotateY(35deg)`,
        zIndex: 9,
      };
    } else if (activeSlide + 1 === index) {
      return {
        opacity: 1,
        transform: `translateX(${baseTranslateX}px) translateZ(-${baseTranslateZ}px) rotateY(-35deg)`,
        zIndex: 9,
      };
    } else if (activeSlide - 2 === index) {
      return {
        opacity: 1,
        transform: `translateX(-${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(35deg)`,
        zIndex: 8,
      };
    } else if (activeSlide + 2 === index) {
      return {
        opacity: 1,
        transform: `translateX(${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(-35deg)`,
        zIndex: 8,
      };
    } else if (index < activeSlide - 2) {
      return {
        opacity: 0,
        transform: `translateX(-${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(35deg)`,
        zIndex: 7,
      };
    } else if (index > activeSlide + 2) {
      return {
        opacity: 0,
        transform: `translateX(${2 * baseTranslateX}px) translateZ(-${
          baseTranslateZ + 100
        }px) rotateY(-35deg)`,
        zIndex: 7,
      };
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "80%", }}>
      <div
        className="slideC"
        {...handlers}
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          perspective: "1000px",
          height: slideSize,
          width: slideSize,
        }}
      >
        {members.map((item, i) => (
          <div
            key={item.id}
            className="slide"
            style={{
              position: "absolute",
              width: slideSize,
              height: slideSize,
              transition: "transform 0.5s ease, opacity 0.5s ease",
              ...getStyles(i),
            }}
            onClick={() => setActiveSlide(i)}
          >
            <div className="sliderContent">{item.icon}</div>
          </div>
        ))}
      </div>

      <Typography color="black" align="center">
        {members[activeSlide]?.title}
      </Typography>

      {/* Member Slider Navigation */}
      <div className="btns">
        <IconButton
          className="slider-btn prev-btn"
          onClick={prev}
          sx={{ color: "blue" }}
          aria-label="Previous member slide"
        >
          <ArrowBack />
        </IconButton>
        <IconButton
          className="slider-btn next-btn"
          onClick={next}
          sx={{ color: "blue" }}
          aria-label="Next member slide"
        >
          <ArrowForward />
        </IconButton>
      </div>
    </Box>
  );
};

export default MemberSlider;
