import React, { useEffect, useState } from "react";
import {
  Dialog,
  styled,
  useTheme,
  DialogContent,
  useMediaQuery,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import axiosInstance from "../../api/axiosConfig";
import { ContestDetail, UserDetail } from "../../types";
import { USER_BY_IDS_API, CONTEST_ENTRIES_API } from "../../api/endpoint";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    backdropFilter: "blur(40px)",
  },
  "& .MuiDialog-paper": {
    borderRadius: theme.breakpoints.up("sm") ? "20px" : "18px",
    backgroundColor: theme.breakpoints.up("sm")
      ? "rgba(255, 255, 255, 1)"
      : "rgba(255, 255, 255, 0.7)",
    backdropFilter: "blur(10px)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
}));

interface ContestEntry {
  entry_id: string;
  work_id: string;
  user_id: string;
  entry_code: string;
  work_ids: string[];
  contest_id: string;
  score: number;
  position: number;
  title: string;
  type: string;
}

interface ContestDetailDialogProps {
  open: boolean;
  onClose: () => void;
  contestDetail: ContestDetail | null;
}

const ContestDetailDialog: React.FC<ContestDetailDialogProps> = ({
  open,
  onClose,
  contestDetail,
}) => {
  const [userData, setUserData] = useState<UserDetail | null>(null);
  const [entries, setEntries] = useState<ContestEntry[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
console.log("contestDetail", contestDetail);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchUserDetails = async () => {
    if (contestDetail) {
      try {
        const response = await axiosInstance.get(
          `${USER_BY_IDS_API}${contestDetail.created_by}`,
        );
        if (response?.data?.data?.[0]) {
          setUserData(response?.data?.data?.[0]);
        }
      } catch (error) {
        console.log("Error fetching user details:", error);
      }
    }
  };

  const fetchContestEntries = async () => {
    if (contestDetail) {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `${CONTEST_ENTRIES_API}/${contestDetail.contest_id}/entries`,
        );
        setEntries(response.data.data);
      } catch (error) {
        console.log("Error fetching contest entries:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (contestDetail) {
      fetchUserDetails();
      fetchContestEntries();
    }
  }, [contestDetail]);

  if (!contestDetail) return null;

  const imageUrl = contestDetail.cr_banner
    ? `${process.env.REACT_APP_DEFAULT_AVATAR}/CONTESTS/${contestDetail?.contest_id}/IMAGES/medium/${contestDetail?.ct_banner}`
    : "";

  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogContent
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: isMobile ? "auto" : 500,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        {/* Background Image */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "blur(20px)",
            zIndex: 0,
          }}
        ></Box>

        {/* Contest Banner */}
        <img
          src={imageUrl}
          alt={contestDetail.title}
          style={{
            width: "100%",
            maxWidth: 500,
            borderRadius: "12px",
            zIndex: 1,
          }}
        />

        {/* Contest Details */}
        <Typography
          variant="h6"
          sx={{ mt: 2, color: "#333", textAlign: "center", zIndex: 1 }}
        >
          {contestDetail.title}
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 1, color: "#666", textAlign: "center", zIndex: 1 }}
        >
          {contestDetail.description.join(" ")}
        </Typography>

        {/* Contest Entries */}
        <Box sx={{ mt: 3, width: "100%", zIndex: 1 }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            entries?.map((entry) => (
              <Box
                key={entry.entry_id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 2,
                  mb: 1,
                  borderRadius: 2,
                  background: "rgba(255, 255, 255, 0.9)",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="body1">{entry.title}</Typography>
                <Typography variant="body2">Score: {entry.score}</Typography>
              </Box>
            ))
          )}
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ContestDetailDialog;
