import React from "react";

interface GradientButtonProps {
  text: string;
  onClick: () => void;
  width?: number;
  height?: number;
}

const transparentStyle = {
  background:
    "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  fontWeight: 400,
  letterSpacing: 1,
};

const GradientButton: React.FC<GradientButtonProps> = ({
  text,
  onClick,
  width = 101, // Default width
  height = 43, // Default height
}) => {
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 101 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <path
          d="M1.03784 32.3272V11.6117C1.03784 7.73212 3.27337 4.42228 7.11566 3.88569C25.3687 1.33661 76.0101 1.26021 93.5348 4.1696C97.0965 4.7609 99.0378 7.89792 99.0378 11.5084V32.3481C99.0378 35.8813 97.1832 38.9832 93.7061 39.6102C76.2332 42.7611 24.9307 42.681 6.85167 39.8977C3.13542 39.3256 1.03784 36.0872 1.03784 32.3272Z"
          stroke="url(#paint0_linear_3874_311911)"
          strokeOpacity="0.9"
          strokeWidth="2"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3874_311911"
            x1="1.03784"
            y1="21.98"
            x2="99.0394"
            y2="21.228"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E02780" />
            <stop offset="0.0001" stopColor="#FA4E76" />
            <stop offset="0.38" stopColor="#A784EA" />
            <stop offset="0.63" stopColor="#ECA75C" />
            <stop offset="0.835" stopColor="#F78D6D" />
            <stop offset="1" stopColor="#CF66C6" />
          </linearGradient>
        </defs>
      </svg>
      <div
        style={{
          fontFamily: "Somatic",
          fontSize: "13px",
          position: "absolute",
          top: '-3px',
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
          ...transparentStyle
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default GradientButton;
