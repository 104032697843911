import React, { useState } from "react";
import { Box, Grid2 as Grid, Typography, Button } from "@mui/material";
import home_lock from "../../assets/home_lock.webp";
import SectionBackground from "../../components/section-background";

interface SafetyProps {
  isMobile?: Boolean | null;
}

const Safety: React.FC<SafetyProps> = ({ isMobile }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        top: "-3rem"
      }}
    >
      <SectionBackground />
      {/* Content */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          pt: { xs: 5, md: 25 },
          pr: { xs: 5, md: 5 },
          pb: { xs: 5, md: 15 },
          pl: { xs: 5, md: 5 },
        }}
      >
        <Grid
          size={{ xs: 12, md: 8 }}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Box p={6}>
            <Typography color="#FFE834" variant="h3" fontSize={40}>
              Ensuring Kid’s Safety &#38; Well Being
            </Typography>
            <Typography
              color={isMobile ? "#0000" : "#FFFFFF"}
              variant="body1"
              fontSize={19}
              fontFamily="Somatic"
              textAlign="justify"
              pt={5}
            >
              Our mission is to protect young minds from harmful online
              influences and create a positive digital experience for every
              child. We are dedicated to creating a safe and nurturing
              environment for children's mental and physical growth. We strongly
              believe in fostering a healthy relationship between children and
              technology by encouraging engaging digital activities that inspire
              learning and support overall well-being.
            </Typography>
            <Typography
              color={isMobile ? "#0000" : "#FFFFFF"}
              variant="body1"
              fontSize={19}
              fontFamily="Somatic"
              textAlign="justify"
            >
              Let's work together to ensure a bright and positive future for our
              children in the digital age.
            </Typography>
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, md: 8 }}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              background:
                "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              fontWeight: 700,
              letterSpacing: 1,
              mb: 2,
            }}
            fontSize={20}
            align="center"
          >
            Ensuring Kid’s Safety & Well Being
          </Typography>
        </Grid>
        <Grid
          size={{ xs: 12, md: 8 }}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <Typography
            align="center"
            fontWeight="normal"
            variant="inherit"
            fontFamily="Somatic"
            fontSize={14}
            color="#1F1D3AB2"
            textAlign="justify"
          >
            Our mission is to protect young minds from harmful online influences
            and create a positive digital experience for every child. We are
            dedicated to creating a safe and nurturing environment for
            children's mental and physical growth. We strongly believe in
            fostering a healthy relationship between children and technology by
            encouraging engaging digital activities that inspire learning and
            support overall well-being.{" "}
            {showMore ? (
              <>
                Let's work together to ensure a bright and positive future for
                our children in the digital age.
              </>
            ) : (
              "..."
            )}
            <Button
              onClick={toggleShowMore}
              sx={{
                padding: 0,
                minWidth: "unset",
                textTransform: "none",
                fontSize: "inherit",
                fontWeight: "bold",
                color: "#ECA75C",
              }}
            >
              {showMore ? "Read Less" : "Read More"}
            </Button>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={home_lock}
              alt="learning"
              style={{
                width: isMobile ? "100%" : 400,
                height: isMobile ? "100%" : 400,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Safety;
