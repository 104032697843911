import React from "react";
import {
  Grid2 as Grid,
  Box,
  Avatar,
  Typography,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import champ_coin from "../../assets/champ_coin.png";

// Define the types for a single player
interface Player {
  uid: string;
  defaultAvatarPath?: string;
  defaultAvatar: boolean;
  avatar: string;
  firstname?: string;
  coins: number;
  handle:string;
}

// Define the props type for the component
interface LeaderboardProps {
  leaderboard: Player[];
  isMobile: boolean;
}

const Leaderboard: React.FC<LeaderboardProps> = ({ leaderboard, isMobile }) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 4 }}
      direction="column"
      sx={{ width: "100%", maxWidth: { md: "80%", sm: "95%" }, mt: 3 }}
    >
      {leaderboard.map((player, index) => (
        <Grid size={12} key={player.uid + index}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: { xs: 1, md: 2 },
              backgroundColor: "#fff",
              borderRadius: "25px",
              boxShadow: "0px 8px 60px 0px #0000001A",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/user/${player.handle}`)}
          >
            <Avatar
              sx={{
                width: isMobile ? 23 : 40,
                height: isMobile ? 23 : 40,
                textAlign: "center",
                color: "#FFFFFF",
                backdropFilter: "blur(16px)",
                background:
                  "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
                borderRadius: "50%",
                p: 1,
                mr: 2,
              }}
            >
              <Typography
                variant={isMobile ? "body1" : "h6"}
                fontSize={isMobile ? 14 : 24}
                fontFamily="Somatic"
              >
                {index + 4}
              </Typography>
            </Avatar>
            <Avatar
              src={
                player.defaultAvatar
                  ? player.defaultAvatarPath
                  : `${process.env.REACT_APP_DEFAULT_AVATAR}/${player?.uid}/PROFILE/IMAGES/medium/${player?.avatar}`
              }
              alt={player.firstname}
              sx={{ width: isMobile ? 40 : 70, height: isMobile ? 40 : 70 }}
            />
            <CardContent sx={{ flex: 1 }}>
              <Typography
                variant={isMobile ? "body1" : "h6"}
                sx={{ color: "#1F1D3A80" }}
                fontSize={isMobile ? 14 : 24}
                fontFamily="Somatic"
              >
                {player.firstname || "Anonymous"}
              </Typography>
            </CardContent>
            <img
              src={champ_coin}
              width={isMobile ? 20 : 40}
              height={isMobile ? 20 : 40}
            />
            <Typography fontSize={isMobile ? 14 : 24} fontFamily="Somatic">
              {player.coins}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default Leaderboard;
