import React from "react";

interface MobileIconsContainerProps {
  width?: number;
  height?: number;
  children?: React.ReactNode;
}

const MobileIconsContainer: React.FC<MobileIconsContainerProps> = ({
  width = 335,
  height = 64,
  children,
}) => {
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 335 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_bii_6655_17678)">
          <path
            d="M1 50.927V12.8169C1 8.922 3.52466 5.60802 7.39298 5.15374C54.1014 -0.331501 282.224 -0.600736 327.911 5.64616C331.642 6.15622 333.99 9.38502 333.99 13.1502V50.5541C333.99 54.2804 331.698 57.4882 328.011 58.0274C282.321 64.7084 53.6647 64.4385 7.25241 58.5289C3.44484 58.0441 1 54.7653 1 50.927Z"
            fill="url(#paint0_linear_6655_17678)"
            fillOpacity="0.2"
          />
          <path
            d="M1 50.927V12.8169C1 8.922 3.52466 5.60802 7.39298 5.15374C54.1014 -0.331501 282.224 -0.600736 327.911 5.64616C331.642 6.15622 333.99 9.38502 333.99 13.1502V50.5541C333.99 54.2804 331.698 57.4882 328.011 58.0274C282.321 64.7084 53.6647 64.4385 7.25241 58.5289C3.44484 58.0441 1 54.7653 1 50.927Z"
            stroke="url(#paint1_linear_6655_17678)"
            strokeOpacity="0.5"
            strokeWidth="0.4212"
          />
        </g>
        <defs>
          <filter
            id="filter0_bii_6655_17678"
            x="-7.63494"
            y="-7.6347"
            width="350.26"
            height="79.2694"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.212" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_6655_17678"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_6655_17678"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="7.03405" dy="7.03405" />
            <feGaussianBlur stdDeviation="3.51702" />
            <feComposite
              in2="hardAlpha"
              operator="arithmetic"
              k2="-1"
              k3="1"
            />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.647059 0 0 0 0 0.647059 0 0 0 0 0.647059 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_6655_17678"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="7.03405" dy="7.03405" />
            <feGaussianBlur stdDeviation="3.51702" />
            <feComposite
              in2="hardAlpha"
              operator="arithmetic"
              k2="-1"
              k3="1"
            />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_innerShadow_6655_17678"
              result="effect3_innerShadow_6655_17678"
            />
          </filter>
          <linearGradient
            id="paint0_linear_6655_17678"
            x1="1"
            y1="32"
            x2="333.921"
            y2="26.4"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E02780" />
            <stop offset="0.0001" stopColor="#FA4E76" />
            <stop offset="0.38" stopColor="#A784EA" />
            <stop offset="0.63" stopColor="#ECA75C" />
            <stop offset="0.835" stopColor="#F78D6D" />
            <stop offset="1" stopColor="#CF66C6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_6655_17678"
            x1="167.495"
            y1="1"
            x2="167.495"
            y2="63"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FCFCFC" />
            <stop offset="0.47" stopColor="#969696" />
            <stop offset="0.97" stopColor="#858181" />
          </linearGradient>
        </defs>
      </svg>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default MobileIconsContainer;
