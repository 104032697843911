import React from "react";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import coin from "../assets/coin.png";
import share from "../assets/share.svg";
import like from "../assets/like.json";
import user_prof_background from "../assets/bg1.webp";
import user_prof_background_mobile from "../assets/bg_mobile.webp";
import { CounterData, UserDetail, PostDetail } from "../types";
import Great from "./great";
import Lovely from "./lovely";
import Omg from "./omg";
import Epic from "./epic";
import { Player } from "@lottiefiles/react-lottie-player";

interface PostDetailsCountersProps {
  postDetail: PostDetail | null;
  counterData: CounterData | null;
  userData: UserDetail | null;
  isMobile: boolean | null;
}

const PostDetailsCounters: React.FC<PostDetailsCountersProps> = ({
  postDetail,
  counterData,
  userData,
  isMobile,
}) => {
  const counterIcons = [
    {
      img: <Great width={isMobile ? 30 : 48} height={isMobile ? 30 : 48} />,
      label: "GREAT",
      value: counterData?.reaction_l1?.N,
    },
    {
      img: <Lovely width={isMobile ? 30 : 48} height={isMobile ? 30 : 48} />,
      label: "LOVELY",
      value: counterData?.reaction_l2?.N,
    },
    {
      img: <Omg width={isMobile ? 30 : 48} height={isMobile ? 30 : 48} />,
      label: "OMG",
      value: counterData?.reaction_l3?.N,
    },
    {
      img: <Epic width={isMobile ? 30 : 48} height={isMobile ? 30 : 48} />,
      label: "EPIC",
      value: counterData?.reaction_l4?.N,
    },
  ];

  const renderDesktopCounters = () => (
    <Grid
      container
      columns={12}
      mt={2}
      mb={2}
      justifyContent="center"
      alignItems="center"
    >
      {/* Star Counter */}
      <Grid
        size={{ xs: 2, md: 2 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={coin || ""}
          alt="coin"
          style={{
            width: isMobile ? 40 : 55,
            height: isMobile ? 40 : 52,
            borderRadius: "10px",
          }}
        />
        <Typography variant={isMobile ? "body1" : "h5"}>
          {counterData?.cws?.N ? parseInt(counterData.cws.N, 10) : 0}
        </Typography>
      </Grid>

      {/* Emoji-based PostDetailsCounters */}
      <Grid
        size={{ xs: 8, md: 8 }}
        style={{
          backgroundImage: `url(${user_prof_background})`,
          // backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          color: "white",
        }}
      >
        <Grid
          container
          columns={4}
          justifyContent="space-around"
          alignItems="center"
          p="25px"
        >
          {counterIcons.map(({ img, label, value }) => (
            <Grid
              key={label}
              size="auto"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {img}
              <span>
                <Typography
                  variant={isMobile ? "inherit" : "body1"}
                  sx={{ padding: "0 0 0 12px" }}
                >
                  {label}
                </Typography>
                <Typography
                  variant={isMobile ? "inherit" : "body1"}
                  align="center"
                >
                  {value ? parseInt(value, 10) : 0}
                </Typography>
              </span>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Likes Counter */}
      <Grid
        size={{ xs: 2, md: 2 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Player
          autoplay
          loop
          src={like}
          style={{
            width: isMobile ? 40 : 43,
            height: isMobile ? 40 : 43,
            borderRadius: "10px",
            marginRight: isMobile ? 8 : 16,
          }}
        />
        <Typography variant={isMobile ? "body1" : "h5"}>
          {counterData?.likes?.N ? parseInt(counterData.likes.N, 10) : 0}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderMobileCounters = () => (
    <Grid
      container
      columns={12}
      p={3}
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      {/* Star Counter */}
      <Grid
        size={{ xs: 4 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={coin || ""}
          alt="coin"
          style={{
            width: 40,
            height: 40,
            borderRadius: "10px",
          }}
        />
        <Typography variant={isMobile ? "body1" : "h5"}>
          {counterData?.cws?.N ? parseInt(counterData.cws.N, 10) : 0}
        </Typography>
      </Grid>
      {/* Likes Counter */}
      <Grid
        size={{ xs: 4 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Player
          autoplay
          loop
          src={like}
          style={{ width: 40, height: 40, borderRadius: "10px" }}
        />
        <Typography variant={isMobile ? "body1" : "h5"}  sx={{padding:"0 0 0 8px"}}>
          {counterData?.likes?.N ? parseInt(counterData.likes.N, 10) : 0}
        </Typography>
      </Grid>
      {/* Likes Counter */}
      <Grid
        size={{ xs: 4 }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={share || ""}
          alt="share"
          style={{
            width: 40,
            height: 40,
            borderRadius: "10px",
          }}
        />
        <Typography fontSize={12}>Share</Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Typography
            variant={isMobile ? "h6" : "h4"}
            sx={{
              background:
                "linear-gradient(89.82deg, rgba(224, 39, 128, 0.9) 0.16%, rgba(250, 78, 118, 0.9) 0.17%, rgba(167, 132, 234, 0.9) 38.04%, rgba(236, 167, 92, 0.9) 62.96%, rgba(247, 141, 109, 0.9) 83.4%, rgba(207, 102, 198, 0.9) 99.85%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              fontWeight: 400,
              letterSpacing: isMobile ? 1 : 3,
            }}
            fontSize={16}
            fontFamily="Somatic"
          >
            {postDetail?.text}
          </Typography> */}
          {/* <Grid size={12}>
        <Typography
          fontFamily="Somatic"
          fontSize={12}
          color="rgba(31, 29, 58, 0.5)"
          fontWeight={400}
          marginBottom={2}
          textAlign="center"
        >
          {postDetail?.text}
        </Typography>
      </Grid> */}
        </Box>
      </Grid>
      <Grid size={12}>
        <Typography
          fontFamily="Somatic"
          fontSize={12}
          color="rgba(31, 29, 58, 0.5)"
          fontWeight={400}
          marginBottom={2}
          textAlign="center"
        >
          {postDetail?.text}
        </Typography>
      </Grid>
      {/* <Grid size={12}>        
        <Typography
          fontFamily="Somatic"
          fontSize={12}
          // color=""
          fontWeight={400}
          marginBottom={2}
          textAlign="right"
        >
          {userData?.days}
        </Typography>
      </Grid> */}
      <Grid size={12}>
        <Grid
          container
          sx={{
            height: 79.2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundImage: `url(${user_prof_background_mobile})`,
            // backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            color: "white",
            textAlign: "center",
            fontSize: "10px",
          }}
        >
          {counterIcons.map(({ img, label, value }) => (
            <Grid
              key={label}
              size="auto"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant={isMobile ? "inherit" : "body1"}>
                {label}
              </Typography>
              {img}
              <Typography
                variant={isMobile ? "inherit" : "body1"}
                align="center"
              >
                {value ? parseInt(value, 10) : 0}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  return isMobile ? renderMobileCounters() : renderDesktopCounters();
};

export default PostDetailsCounters;
