import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { Avatar, Typography } from "@mui/material";
import axiosInstance from "../../api/axiosConfig";
import { ClanListing, UserDetail, ClanDetailsResponse } from "../../types";
import {
  CLANS_LIST_API,
  CLANS_DETAILS_API,
  USER_BY_IDS_API,
} from "../../api/endpoint";
import ClanSlider from "./clan-slider";
import MemberSlider from "./member-slider";
import Loader from "../../components/loader";

interface TopClansProps {
  isMobile: boolean | null;
}

const TopClans: React.FC<TopClansProps> = ({ isMobile }) => {
  const [clans, setClans] = useState<ClanListing[]>([]);
  const [userDetails, setUserDetails] = useState<UserDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<string | null>(null);
  const [currentMemberPage, setCurrentMemberPage] = useState<string | null>(
    null,
  );
  const isFetching = useRef(false);
  const [activeClanSlide, setActiveClanSlide] = useState(2);
  const [activeMemberSlide, setActiveMemberSlide] = useState(0);
  const [stopExecution, setStopExecution] = useState<boolean | null>(false);

  const slideWidth = isMobile ? 127 : 345;
  const slideHeight = isMobile ? 127 : 345;
  const memberSlideSize = isMobile ? 100 : 200;

  const fetchClans = async (pageToken: string | null = null) => {
    if (!stopExecution) {
      if (isFetching.current) return;
      isFetching.current = true;

      try {
        const response = await axiosInstance.get(CLANS_LIST_API, {
          params: { sortByCoins: true, page: pageToken || null, count: 5 },
        });

        if (!response?.data?.data?.length) {
          setStopExecution(true);
        }

        setClans((prevClans) => [...prevClans, ...response.data.data]);
        setCurrentPage(response.data.page);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
        isFetching.current = false;
      }
    }
  };

  const fetchMembersForClan = async (
    clanId: string,
    pageToken: string | null = null,
  ) => {
    try {
      const clanDetailsResponse = await axiosInstance.get<ClanDetailsResponse>(
        CLANS_DETAILS_API,
        {
          params: { clan_id: clanId, page: pageToken || null, count: 5 },
        },
      );
      const clanDetails = clanDetailsResponse.data.data;
      setCurrentMemberPage(clanDetailsResponse.data.page);

      const memberIds = clanDetails.clan_members.map(
        (member) => member.member_id,
      );

      if (memberIds.length) {
        const userDetailsResponse = await axiosInstance.get(
          `${USER_BY_IDS_API}${memberIds.join(",")}`,
        );
        setUserDetails((prevDetails) => {
          const existingIds = new Set(prevDetails.map((user) => user.uid));
          const newDetails = userDetailsResponse.data.data.filter(
            (user: UserDetail) => !existingIds.has(user.uid),
          );
          const allUsers = newDetails.map((userDetail: UserDetail) => {
            if (userDetail?.defaultAvatar) {
              const slideNum = Math.floor(Math.random() * 8) + 1;
              userDetail.defaultAvatarPath = `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/UserAvatars/Avatar${slideNum}.png`;
            }
            return userDetail;
          });
          const combinedUsers = [...prevDetails, ...allUsers];

          const middleIndex = Math.floor(combinedUsers.length / 2);
          setActiveMemberSlide(middleIndex);

          return combinedUsers;
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.message);
      } else {
        setError("An unknown error occurred");
      }
    }
  };

  useEffect(() => {
    fetchClans();
  }, []);

  useEffect(() => {
    if (clans.length > 0 && clans?.[activeClanSlide]?.clan_id) {
      fetchMembersForClan(clans[activeClanSlide].clan_id);
    }
  }, [activeClanSlide, clans]);

  const handleNextClan = async () => {
    const newActiveSlide = activeClanSlide + 1;
    if (newActiveSlide >= clans.length) {
      if (currentPage) {
        await fetchClans(currentPage);
      } else {
        setStopExecution(true);
        console.log("No more clans to fetch");
      }
    }
    if (newActiveSlide < clans.length) {
      setActiveClanSlide(newActiveSlide);
      setActiveMemberSlide(0);
      setUserDetails([]);
    } else {
      setActiveClanSlide(0);
    }
  };

  const handlePrevClan = () => {
    if (activeClanSlide > 0) {
      setActiveClanSlide(activeClanSlide - 1);
      setUserDetails([]);
    }
  };

  const handleNextMember = async () => {
    const newActiveMemberSlide = activeMemberSlide + 1;
    if (newActiveMemberSlide >= userDetails.length) {
      if (currentMemberPage) {
        await fetchMembersForClan(
          clans[activeClanSlide].clan_id,
          currentMemberPage,
        );
      } else {
        console.log("No more members to fetch");
      }
    }
    if (newActiveMemberSlide < userDetails.length) {
      setActiveMemberSlide(newActiveMemberSlide);
    } else {
      setActiveMemberSlide(0);
    }
  };

  const handlePrevMember = () => {
    if (activeMemberSlide > 0) {
      setActiveMemberSlide(activeMemberSlide - 1);
    }
  };

  const clanSliderData = clans.map((clan, index) => ({
    id: clan.clan_id + index,
    coverImage: clan.clan_cover.defaultCover
      ? `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/ClanAvatars/medium/${clan.clan_cover?.name}`
      : `${process.env.REACT_APP_DEFAULT_AVATAR}/${clan?.clan_id}/PROFILE/IMAGES/medium/${clan.clan_cover?.name}`,
    title: clan.clan_name || "Unknown Clan",
  }));

  const memberSliderData = userDetails.map((userDetail, index) => ({
    id: userDetail.uid + index,
    icon: (
      <Avatar
        alt={userDetail?.handle}
        src={
          userDetail?.defaultAvatar
            ? userDetail?.defaultAvatarPath
            : `${process.env.REACT_APP_DEFAULT_AVATAR}/${userDetail.uid}/PROFILE/IMAGES/medium/${userDetail.avatar}`
        }
        sx={{
          width: memberSlideSize,
          height: memberSlideSize,
          m: "auto",
        }}
      />
    ),
    title: userDetail?.firstname || "Anonymous",
  }));

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography
        variant={isMobile ? "h6" : "h4"}
        sx={{
          background:
            "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          color: "transparent",
          fontWeight: 400,
          letterSpacing: isMobile ? 1 : 3,
          mb: isMobile ? 4 : 5,
        }}
      >
        Top Clans
      </Typography>

      {loading ? (
        <Loader />
      ) : (
        <>
          {/* Clan Cover Slider */}
          <ClanSlider
            clans={clanSliderData}
            activeSlide={activeClanSlide}
            setActiveSlide={setActiveClanSlide}
            next={handleNextClan}
            prev={handlePrevClan}
            slideWidth={slideWidth}
            slideHeight={slideHeight}
          />

          {/* Member Avatar Slider */}
          {memberSliderData.length ? (
            <MemberSlider
              members={memberSliderData}
              activeSlide={activeMemberSlide}
              setActiveSlide={setActiveMemberSlide}
              next={handleNextMember}
              prev={handlePrevMember}
              slideSize={memberSlideSize}
            />
          ) : null}
        </>
      )}
    </Box>
  );
};

export default TopClans;
