import React from "react";
import { Grid2 as Grid } from "@mui/material";
import GradientButton from "../../svg/gradient-button";
import ActiveGradientButton from "../../svg/active-gradient-button";
import { text } from "node:stream/consumers";

// Define the types for the props
interface TimelineButtonsProps {
  page: string;
  timeline: string;
  handleTimelineChange: (newTimeline: string) => void;
  items: string[];
}

const getText = (item: string,page:string,) => {
  console.log("page",page)
  let type = "";

  switch (item) {
    case "all_time":
      type = "ALL TIME";
      break;
    case "current_month":
      type = "MONTHLY";
      break;
    case "current_week":
      type = "WEEKLY";
      break;
    default:
      type = ""; 
      break;
  }

  return type;
};

const check = (item:string,timeline:string)=>{
  console.log("timeline",timeline)
  return item.toUpperCase()
}

const TimelineButtons: React.FC<TimelineButtonsProps> = ({
  page,
  timeline,
  handleTimelineChange,
  items,
}) => {
  return (
    <Grid container spacing={{ xs: 1, md: 3 }}>
      {items?.map((item, index) => (
        <Grid >
          {timeline === item ? (
            <ActiveGradientButton
            text={page === "champs" ? getText(item,page) || "" : check(item,timeline)}
              onClick={() => handleTimelineChange(item)}
            />
          ) : (
            <GradientButton
            text={page === "champs" ? getText(item, page) || "" : check(item,timeline)}
              onClick={() => handleTimelineChange(item)}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default TimelineButtons;
