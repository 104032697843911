import React from "react";
import { Typography, Grid2 as Grid, Box, useMediaQuery } from "@mui/material";
import home_footer from "../assets/home_footer.webp";
import app_store from "../assets/app_store.svg";
import google_play from "../assets/google_play.svg";
import logo from "../assets/logo.webp";
import android from "../assets/android.svg";
import iphone from "../assets/iphone.svg";

const Footer = React.forwardRef((props, ref) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const imgWidth = isMobile ? "24%" : "40%";
  const iconWidth = isMobile ? "50%" : "100%";
  return (
    <Box ref={ref}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant={isMobile ? "h6" : "h4"}
          sx={{
            background:
              "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontWeight: 400,
            letterSpacing: isMobile ? 1 : 3,
            marginBottom: isMobile ? 1 : 3,
          }}
        >
          Download Our App
        </Typography>
      </Box>
      <Typography
        variant={isMobile ? "body2" : "h6"}
        fontFamily="Somatic"
        sx={{
          color: "#1F1D3A80",
          m: "auto",
          width: { xs: "80%", sm: "60%" },
          marginBottom: isMobile ? 1 : 3,
        }}
        align="center"
      >
        Discover more functionalities and explore additional features with our
        mobile app, available on stores soon. Download and enhance your
        experience today!
      </Typography>

      {/* App Store and Google Play Logos */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{ marginTop: 3 }}
      >
        <Grid columns={{ xs: 12, sm: 6, md: 3 }} sx={{ textAlign: "right" }}>
          <img
            src={app_store}
            alt="app store"
            style={{ width: iconWidth, height: "auto" }}
          />
        </Grid>
        <Grid columns={{ xs: 12, sm: 6, md: 3 }}>
          <img
            src={google_play}
            alt="google play"
            style={{ width: iconWidth, height: "auto" }}
          />
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Box
        component="footer"
        sx={{
          backgroundImage: `url(${home_footer})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          // marginTop: isMobile ? "2rem" : "-3rem",
          marginTop: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          color: "white",
          // paddingTop: isMobile ? 0 : 14,
        }}
      >
        {/* Content on top of Footer */}
        <Box sx={{ paddingBottom: isMobile ? 5 : 5, textAlign: "center" }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            spacing={2}
          >
            {/* App Previews */}
            <Grid columns={{ xs: 12 }}>
              <img
                src={iphone}
                alt="iphone"
                style={{ width: imgWidth, height: "auto" }}
              />
              <img
                src={android}
                alt="android"
                style={{ width: imgWidth, marginLeft: "15px" }}
              />
            </Grid>
            {/* Logo */}
            <Grid columns={{ xs: 12 }}>
              <img src={logo} alt="logo" width={isMobile ? "60%" : "90%"} />
            </Grid>

            {/* Footer Links */}
            <Grid columns={{ xs: 12, md: 6 }}>
              <Typography fontSize={isMobile ? 7 : 12}>
                <a href="#" className="bottom-link">
                  About Us
                </a>
                {" | "}
                <a href="#" className="bottom-link">
                  FAQ
                </a>
                {" | "}
                <a href="#" className="bottom-link">
                  Contact
                </a>
                {" | "}
                <a href="#" className="bottom-link">
                  Privacy Policy
                </a>
                {" | "}
                <a href="#" className="bottom-link">
                  COPPA Policy
                </a>
                {" | "}
                <a href="#" className="bottom-link">
                  Cookies Policy
                </a>
                {" | "}
                <a href="#" className="bottom-link">
                  Terms And Conditions
                </a>
              </Typography>
            </Grid>

            {/* Footer Info */}
            <Grid columns={{ xs: 12 }}>
              <Typography className="bottom-link" fontSize={isMobile ? 7 : 12}>
                ChampsVerse (C) 2024
              </Typography>
            </Grid>
            <Grid columns={{ xs: 12 }}>
              <Typography className="bottom-link" fontSize={isMobile ? 7 : 12}>
                Version - V2.0
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
});

export default Footer;
