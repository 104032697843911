import React, { useState } from "react";
import { Box, Grid2 as Grid, Typography, Button } from "@mui/material";
import home_creativity from "../../assets/home_creativity.webp";
import SectionBackground from "../../components/section-background";

interface CreativityProps {
  isMobile?: Boolean | null;
}

const Creativity: React.FC<CreativityProps> = ({ isMobile }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderImage = () => (
    <Grid size={{ xs: 12, md: 5 }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          src={home_creativity}
          alt="learning"
          style={{
            width: isMobile ? "100%" : 554,
            height: isMobile ? "100%" : 554,
          }}
        />
      </Box>
    </Grid>
  );

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <SectionBackground />
      {/* Content */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          pt: { xs: 5, md: 25 },
          pr: { xs: 5, md: 5 },
          pb: { xs: 5, md: 15 },
          pl: { xs: 5, md: 5 },
        }}
      >
        {!isMobile && renderImage()}
        <Grid
          size={{ xs: 12, md: 7 }}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Box p={6}>
            <Typography color="#FFE834" variant="h3" fontSize={40}>
              Creativity & Imagination Of Young Artists
            </Typography>
            <Typography
              color={isMobile ? "#0000" : "#FFFFFF"}
              variant="body1"
              fontSize={19}
              fontFamily="Somatic"
              textAlign="justify"
              pt={5}
            >
              The creativity and imagination of young artists are boundless,
              often leading to innovative and fresh perspectives in art. They
              explore a wide range of mediums and techniques, expressing
              emotions, ideas, and narratives with a raw, uninhibited approach.
              Their work reflects a unique blend of personal experiences,
              cultural influences, and a curiosity about the world, resulting in
              art that is both thought-provoking and inspiring. Young artists
              frequently push the boundaries of traditional art forms,
              introducing bold, experimental styles that challenge conventions
              and invite new ways of seeing.
            </Typography>
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              background:
                "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              fontWeight: 700,
              letterSpacing: 1,
              mb: 2,
            }}
            fontSize={20}
            align="center"
          >
            Creativity & Imagination Of Young Artists
          </Typography>
        </Grid>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: { xs: "block", md: "none" },
            textAlign: "justify",
          }}
        >
          <Typography
            align="center"
            fontWeight="normal"
            variant="inherit"
            fontFamily="Somatic"
            fontSize={14}
            color="#1F1D3AB2"
          >
            The creativity and imagination of young artists are boundless, often
            leading to innovative and fresh perspectives in art. They explore a
            wide range of mediums and techniques, expressing emotions, ideas,
            and narratives with a raw, uninhibited approach.{" "}
            {showMore ? (
              <>
                Their work reflects a unique blend of personal experiences,
                cultural influences, and a curiosity about the world, resulting
                in art that is both thought-provoking and inspiring. Young
                artists frequently push the boundaries of traditional art forms,
                introducing bold, experimental styles that challenge conventions
                and invite new ways of seeing.
              </>
            ) : (
              "..."
            )}
            <Button
              onClick={toggleShowMore}
              sx={{
                padding: 0,
                minWidth: "unset",
                textTransform: "none",
                fontSize: "inherit",
                fontWeight: "bold",
                color: "#ECA75C",
              }}
            >
              {showMore ? "Read Less" : "Read More"}
            </Button>
          </Typography>
        </Grid>
        {isMobile && renderImage()}
      </Grid>
    </Box>
  );
};

export default Creativity;
