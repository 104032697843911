import React from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import c_achievement from "../../assets/c_achievement.png";
import c_activity from "../../assets/c_activity.png";
import c_clans from "../../assets/c_clans.png";
import c_coin from "../../assets/c_coin.png";
import c_goodness from "../../assets/c_goodness.png";
import c_popularity from "../../assets/c_popularity.png";
import active_frame from "../../assets/active_frame.png";

// Define the types for the props
interface ChampIconsProps {
  isMobile: Boolean;
  champType: string;
  handleChampTypeChange: (type: string) => void;
}

const iconStyles = {
  // active: {
  //   border: "2.02px solid rgba(253, 210, 101, 1)",
  //   boxShadow:
  //     "inset -4px -4px 20px rgba(255, 255, 255, 0.2), inset 4px 4px 20px rgba(0, 0, 0, 0.3), 0px 5px 15px rgba(0, 0, 0, 0.4)",
  //   cursor: "pointer",
  // },
  active: {
    backgroundImage: `url(${active_frame})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    cursor: "pointer",
  },
  default: {
    cursor: "pointer",
  },
};

const ChampIcons: React.FC<ChampIconsProps> = ({
  isMobile,
  champType,
  handleChampTypeChange,
}) => {
  const renderIcon = (src: string, label: string, type: string) => (
    <Grid
      size={{ sm: "auto" }}
      pl={isMobile ? 1 : 3}
      pr={isMobile ? 1 : 3}
      sx={{ textAlign: "center" }}
    >
      <img
        src={src}
        alt={label}
        width={isMobile ? 30 : 50}
        height={isMobile ? 30 : 50}
        style={champType === type ? iconStyles.active : iconStyles.default}
        onClick={() => handleChampTypeChange(type)}
      />
      <Typography color="rgba(31, 29, 58, 0.6)" fontSize={isMobile ? 5 : 14}>
        {label}
      </Typography>
    </Grid>
  );
  return (
    <Grid container justifyContent="center" alignItems="center">
      {renderIcon(c_coin, "COINS", "coins")}
      {renderIcon(c_activity, "ACTIVITY", "cws")}
      {renderIcon(c_popularity, "POPULARITY", "cps")}
      {renderIcon(c_goodness, "GOODNESS", "cgs")}
      {renderIcon(c_achievement, "ACHIEVEMENT", "cas")}
      {renderIcon(c_clans, "CLANS", "clans")}
    </Grid>
  );
};

export default ChampIcons;
