import React, { useState } from "react";
import { Box, Grid2 as Grid, Typography, Button } from "@mui/material";
import home_achievement from "../../assets/home_achievement.webp";
import SectionBackground from "../../components/section-background";

interface AchievementProps {
  isMobile?: Boolean | null;
}

const transparentStyle = {
  background:
    "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  fontWeight: 700,
  letterSpacing: 1,
};

const Achievement: React.FC<AchievementProps> = ({ isMobile }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <SectionBackground />
      {/* Content */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          pt: { xs: 5, md: 25 },
          pr: { xs: 5, md: 5 },
          pb: { xs: 5, md: 15 },
          pl: { xs: 5, md: 5 },
        }}
      >
        <Grid
          size={{ xs: 12, md: 8 }}
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Box p={4}>
            <Typography color="#FFE834" variant="h3" fontSize={40}>
              Recognising Every Child’s Achievement
            </Typography>
            <Typography
              component="div"
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={19}
              pt={5}
              gutterBottom
              fontFamily="somatic"
            >
              Our reward system in the ChampsVerse is designed to motivate and
              inspire children by providing four different types of scores.
            </Typography>
            <Typography
              component="div"
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={19}
              gutterBottom
            >
              <span style={{ color: "#FFE834" }}>Goodness Score: </span>
            </Typography>
            <Typography
              component="div"
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={19}
              gutterBottom
              fontFamily="somatic"
            >
              Earned by helping others, showing kindness to friends, and
              engaging in positive behavior.
            </Typography>
            <Typography
              component="div"
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={19}
              gutterBottom
              fontFamily="somatic"
            >
              <span style={{ color: "#FFE834", fontFamily:"Righteous" }}>Achievement Score: </span>
              Gained through academic progress, completing tasks, and hitting
              personal goals, both at home and in school.
            </Typography>
            <Typography
              component="div"
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={19}
              gutterBottom
              fontFamily="somatic"
            >
              <span style={{ color: "#FFE834", fontFamily:"Righteous" }}>Popularity Score: </span>A fun
              way for kids to see how well they’re engaging with their peers,
              participating in group activities, and sharing creativity in the
              metaverse!
            </Typography>
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              background:
                "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              fontWeight: 700,
              letterSpacing: 1,
              mb: 2,
            }}
            fontSize={20}
            align="center"
          >
            Recognising Every Child’s Achievement
          </Typography>
        </Grid>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: { xs: "block", md: "none" },
            textAlign: "center",
          }}
        >
          <Typography
            component="div"
            fontWeight="normal"
            variant="inherit"
            fontSize={14}
            color="#1F1D3AB2"
            textAlign="justify"
          >
            Our reward system in the Metaverse is designed to motivate and
            inspire children by providing three different types of scores. Let's
            celebrate the successes of our kids and encourage them to keep
            reaching for their goals!{" "}
            {!showMore && (
              <>
                ...{" "}
                <Button
                  onClick={toggleShowMore}
                  sx={{
                    padding: 0,
                    minWidth: "unset",
                    textTransform: "none",
                    fontSize: "inherit",
                    fontWeight: "bold",
                    color: "#ECA75C",
                    display: "inline",
                  }}
                >
                  Read More
                </Button>
              </>
            )}
          </Typography>
          {showMore && (
            <>
              <Typography
                component="div"
                color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
                fontWeight="normal"
                variant="inherit"
                fontSize={14}
              >
                <span style={transparentStyle}>Goodness Score: </span>
              </Typography>
              <Typography
                component="div"
                color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
                fontWeight="normal"
                variant="inherit"
                fontSize={14}
              >
                Earned by helping others, showing kindness to friends, and
                engaging in positive behavior.
              </Typography>
              <Typography
                component="div"
                color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
                fontWeight="normal"
                variant="inherit"
                fontSize={14}
              >
                <span style={transparentStyle}>Achievement Score: </span>
                Gained through academic progress, completing tasks, and hitting
                personal goals, both at home and in school.
              </Typography>
              <Typography
                component="div"
                color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
                fontWeight="normal"
                variant="inherit"
                fontSize={14}
              >
                <span style={transparentStyle}>Popularity Score: </span>A fun
                way for kids to see how well they’re engaging with their peers,
                participating in group activities, and sharing creativity in the
                metaverse!
              </Typography>
              <Button
                onClick={toggleShowMore}
                sx={{
                  padding: 0,
                  minWidth: "unset",
                  textTransform: "none",
                  fontSize: "inherit",
                  fontWeight: "bold",
                  color: "#ECA75C",
                  mt: 1,
                }}
              >
                Read Less
              </Button>
            </>
          )}
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={home_achievement}
              alt="learning"
              style={{
                width: isMobile ? "100%" : 510,
                height: isMobile ? "100%" : 397,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Achievement;
