import React, { useState } from "react";
import { Avatar, Button, Grid2 as Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserDetail, PostDetail } from "../types";
import ruby from "../assets/ruby.json";
import diamond from "../assets/diamond.json";
import silver_star from "../assets/silver.json";
import { Player } from "@lottiefiles/react-lottie-player";

interface UserDetailsProps {
  userData: UserDetail | null;
  isMobile: boolean | null;
  postDetail: PostDetail | null;
}

const UserDetails: React.FC<UserDetailsProps> = ({
  postDetail,
  userData,
  isMobile,
}) => {
  const [showFullBio, setShowFullBio] = useState(false);
  const navigate = useNavigate();

  const toggleBio = () => {
    setShowFullBio((prev) => !prev);
  };

  const renderBio = (bio: string | undefined) => {
    if (!bio) return "";
    const bioPreview = bio.length > 100 ? bio.substring(0, 100) + "..." : bio;
    return showFullBio ? bio : bioPreview;
  };

  return (
    <Grid
      container
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 10,
        color: isMobile ? "black" : "white",
        padding: isMobile ? 0 : "15px",
        width: "100%",
      }}
      alignItems="center"
    >
      {!isMobile ? (
        <>
          <Grid size={{ xs: 2, md: 1 }}>
            <Avatar
              alt={userData?.handle}
              src={
                userData?.defaultAvatar
                  ? `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/UserAvatars/Avatar2.png`
                  : `${process.env.REACT_APP_DEFAULT_AVATAR}/${userData?.uid}/PROFILE/IMAGES/medium/${userData?.avatar}`
              }
              sx={{ width: 50, height: 50 }}
              onClick={() => navigate(`/user/${userData?.handle}`)}
            />
          </Grid>
          <Grid size={{ xs: 10, md: 11 }}>
            <Typography variant="inherit" gutterBottom>
              {userData?.firstname || "Anonymous"}
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography variant="inherit" gutterBottom>
              {renderBio(postDetail?.text)}
              {postDetail?.text?.length && postDetail?.text.length > 100 ? (
                <Button
                  onClick={toggleBio}
                  sx={{ color: "white", textTransform: "capitalize" }}
                >
                  {showFullBio ? "See Less" : "See More"}
                </Button>
              ) : null}
            </Typography>
          </Grid>
        </>
      ) : (
        <Grid
          size={{ xs: 12 }}
          sx={{
            backdropFilter: "blur(17.128063201904297px)",
            background: "rgba(31, 29, 58, 0.1)",
            padding: "5px",
            borderRadius: "25px",
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              size={{ xs: 8 }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Avatar
                alt={userData?.handle}
                src={
                  userData?.defaultAvatar
                    ? `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/UserAvatars/Avatar2.png`
                    : `${process.env.REACT_APP_DEFAULT_AVATAR}/${userData?.uid}/PROFILE/IMAGES/medium/${userData?.avatar}`
                }
                sx={{ width: 30, height: 30 }}
                onClick={() => navigate(`/user/${userData?.handle}`)}
              />
              <Typography
                variant="inherit"
                color="rgba(255, 254, 254, 1)"
                pl={1}
                gutterBottom
              >
                {userData?.firstname || "Anonymous"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Player autoplay loop src={silver_star}  style={{width:"30px",height:"30px"}}/>
              <Player autoplay loop src={diamond}  style={{width:"30px",height:"30px"}}/>
              <Player autoplay loop src={ruby}  style={{width:"30px",height:"30px"}}/>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default UserDetails;
